// 异常类型
export const abnormalTypeOptions = [
    { value:1,label:"电瓶离车" },
    { value:2,label:"电瓶过温" },
    { value:3,label:"温感线故障" },
    { value:4,label:"车辆超速" },
    { value:5,label:"车辆摔倒" },
    { value:6,label:"掉线" },
    // { value:7,label:"WiFi设备被拆" },
    // { value:8,label:"WiFi设备过温" },
    { value:9,label:"4G设备被拆" },
    { value:10,label:"4G设备过温" },
    { value:11,label:"地锁设备故障" },
    { value:12,label:"地锁异常解锁" },
    { value:13,label:"充电桩故障" },
    { value:14,label:"设备脱落" },
    { value:15,label:"设备电源断开" },
    { value:16,label:"设备被拆" },
    { value:17,label:"充放电柜电池仓过温" },
]
// 设备类型/异常类型联级选项  设备类型：1 4G-Pro、2 感应器设备、3 道闸设备、4 WiFi开关、5 Air-WiFi、6 Air-4G、7 4G开关、8 地锁、9 充电桩 10 power-4G 11 充放电柜
export const equipTypeOptions = [
    {
        value:1,
        label:'车载设备',
        children:[
            {
                value:1,
                label:'4G-Pro',
                children:[
                    {value:1,label:'电瓶离车'},
                    {value:2,label:'电瓶过温'},
                    {value:3,label:'温感异常'},
                    {value:4,label:'超速'},
                    {value:5,label:'摔倒'},
                ]
            },
            // {
            //     value:5,
            //     label:'Air-WiFi',
            //     children:[
            //         {value:7,label:'设备被拆'},
            //         {value:8,label:'设备过温'},
            //     ]
            // },
            {
                value:6,
                label:'Air-4G',
                children:[
                    {value:9,label:'设备被拆'},
                    {value:10,label:'设备过温'},
                ]
            },
            {
                value:10,
                label:'Power-4G',
                children:[
                    {value:3,label:'温感线故障'},
                    // {value:9,label:'设备被拆'},
                    {value:10,label:'设备过温'},
                    {value:14,label:'设备脱落'},
                    {value:15,label:'设备电源断开'},
                    {value:16,label:'设备被拆'},
                ]
            },
        ]
    },
    {
        value:2,
        label:'感应器设备',
        children:[
            {value:6,label:'掉线'},
        ]
    },
    {
        value:3,
        label:'道闸设备',
        children:[
            {value:6,label:'掉线'},
        ]
    },
    // {
    //     value:4,
    //     label:'WiFi开关',
    //     children:[
    //         {value:6,label:'掉线'},
    //     ]
    // },
    {
        value:7,
        label:'4G开关',
        children:[
            {value:6,label:'掉线'},
        ]
    },
    {
        value:8,
        label:'地锁',
        children:[
            {value:11,label:'设备故障'},
            {value:12,label:'异常解锁'},
        ]
    },
    {
        value:9,
        label:'充电桩',
        children:[
            {value:13,label:'设备故障'},
        ]
    },
    {
        value:11,
        label:'充放电柜',
        children:[
            {value:17,label:'电池仓过温'},
        ]
    },
];

// 根据异常类型value值获取映射中文
export function getAbnormalTypeLabel(value){
    for(let i = 0;i < abnormalTypeOptions.length;i++) {
      if(abnormalTypeOptions[i].value == value) {
        return abnormalTypeOptions[i].label;
      }
    }
    return '未知';
};