<template>
  <div class="outContainer">
    <!-- 筛选条件栏 -->
    <div class="search">
      <el-cascader class="each" placeholder="设备类型 / 异常类型" v-model="abnormalType"
        clearable
        :options="equipTypeOptions"
        :props="{ expandTrigger: 'hover',checkStrictly:true }"
        @change="handleChoose"
      ></el-cascader>
      <el-select class='each' placeholder="是否处理" v-model="searchInfo.param.status"
        :disabled="searchInfo.param.deviceType == 1 && searchInfo.param.abnormalType == 4"
        clearable
        >
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-cascader class="each" placeholder="所属商户 / 所属小区" v-model="unitId"
        clearable
        :options="managerUnitOptions"
        :props="props"
        @change="chooseMerchant"
        v-if="userInfo.roleLevel < 5"
      ></el-cascader>
      <el-select class='each' placeholder="所属小区" v-model="searchInfo.param.unitId"
       clearable 
       v-if="userInfo.roleLevel >= 5 && userInfo.roleLevel < 10"
      >
        <el-option
          v-for="item in unitOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
      <el-input class="each" placeholder="设备编号" v-model="searchInfo.param.deviceId">
      </el-input>
      <el-input class="each" placeholder="车牌号" v-model="searchInfo.param.plateNumber"
        v-if="searchInfo.param.deviceBikeType">
      </el-input>
      <el-input class="each" placeholder="车主名称" v-model="searchInfo.param.name" 
       v-if="searchInfo.param.deviceBikeType">
      </el-input>
      <el-input class="each" placeholder="车主电话" v-model="searchInfo.param.phone" 
       v-if="searchInfo.param.deviceBikeType">
      </el-input>
      <el-date-picker class='each' start-placeholder="异常时间" range-separator="至" end-placeholder="异常时间" v-model="time"
       type="daterange"
       @change='chooseTime'
      >
      </el-date-picker>
      <el-button size="mini" type="primary" icon="el-icon-search" @click="handleSearch('search')">搜索</el-button>
      <el-button size="mini" type="primary" icon="el-icon-close" @click="handleSearch('clear')">清空搜索条件</el-button>
      <el-button size="mini" icon="el-icon-download" @click="educe">导出</el-button>
    </div>
    <!-- 表格 -->
    <div class="tableContainer">
      <el-table
       :data="tableData"
       style="width: 100%;"
       height="100%"
       :row-class-name="tableRowClassName">
        <div v-if="!searchInfo.param.deviceType || searchInfo.param.deviceBikeType || searchInfo.param.deviceType == 1  || searchInfo.param.deviceType == 5 || searchInfo.param.deviceType == 6 || searchInfo.param.deviceType == 10">
          <el-table-column align="center" label="车辆信息" prop="brand" >
            <template slot-scope="scope">
              <p>{{ scope.row.plateNumber ? scope.row.plateNumber : '--' }}</p>
              <p>{{ scope.row.brand ? scope.row.brand : '--' }}</p>
            </template>
          </el-table-column>
        </div>
        <div v-if="searchInfo.param.deviceBikeType || searchInfo.param.deviceType == 1 || searchInfo.param.deviceType == 5 || searchInfo.param.deviceType == 6 || searchInfo.param.deviceType == 10">
          <el-table-column align="center" label="车主信息" prop="ownerName">
            <template slot-scope="scope">
              <p>{{ scope.row.ownerName ? scope.row.ownerName : '--' }}</p>
              <p>{{ scope.row.ownerPhone ? scope.row.ownerPhone : '--' }}</p>
            </template>
          </el-table-column>
        </div>
        <div>
          <el-table-column align="center" label="设备编号" prop="deviceId">
          </el-table-column>
          <el-table-column align="center" label="异常类型" prop="abnormalType">
            <template slot-scope="scope">
              <span v-if="scope.row.abnormalType != 13">{{ scope.row.abnormalTypeStr }}</span>
              <div v-else class="reasonContainer">
                <p>
                  <span>充电桩故障</span>
                  <span> - {{ scope.row.remarks ? scope.row.remarks : '未知' }}</span>
                </p>
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content" class="content">
                    <p style="margin-bottom: 10px;">新能源桩：0=正常，1=存储器损坏，2=接地错误，3=继电器错误，4=计量错误，5=CP电压错误，6=急停错误，7=漏电错误，8=短路错误，9=门开，10=输入电压异常</p>
                    <p>电瓶车桩：0=正常，1=断电，2=水浸，3=热熔胶触发，4=烟感报警,5=温感报警，6=水位报警。</p>
                  </div>
                  <i class="el-icon-info"></i>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="所属小区" prop="unitName">
            <template slot-scope="scope">
              <span> {{ scope.row.unitName ? scope.row.unitName : "小区外" }}</span>
            </template>
          </el-table-column>
        </div>
        <div v-if="!searchInfo.param.deviceType || searchInfo.param.deviceType == 5 || searchInfo.param.deviceType == 6">
          <el-table-column align="center" label="具体区域" prop="remarks">
            <template slot-scope="scope">
              <span> {{ scope.row.remarks ? scope.row.remarks : "--" }}</span>
            </template>
          </el-table-column>
        </div>
        <div>
          <el-table-column align="center" label="温度" prop="firstTemp">
            <template slot-scope="scope">
              <p>{{ scope.row.firstTemp ? scope.row.firstTemp+'℃' : "--" }}</p>
              <p>{{ scope.row.secondTemp ? scope.row.secondTemp+'℃' : "--" }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" label="是否处理" prop="status">
            <template slot-scope="scope">
              <span> {{ scope.row.status == 1 ? "已处理" : "待处理" }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="处理结果" prop="processingResult">
            <template slot-scope="scope">
              <span> {{ scope.row.status == 1 ? scope.row.processingResult : "待处理" }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="创建时间" prop="createTime">
          </el-table-column>
          <el-table-column align="center" label="处理时间" prop="updateTime">
            <template slot-scope="scope">
              <span> {{ scope.row.status == 1 ? scope.row.updateTime : "待处理" }}</span>
            </template>
          </el-table-column>
        </div>
        <el-table-column align="center" label="操作" fixed="right" width="300">
          <template slot-scope="scope">
            <el-button size="mini" @click="carSituation(scope.row)" v-if="scope.row.deviceType == 1 || scope.row.deviceType == 5 || scope.row.deviceType == 6 || scope.row.deviceType == 10">车辆情况</el-button>
            <el-button size="mini" @click="handleSolve(scope.row)" v-if="(scope.row.abnormalType != 4 && scope.row.abnormalType != 5) && scope.row.status == 0">处理</el-button>
            <el-button size="mini" @click="handleGps(scope.row)" >查看定位</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <pagination v-bind:child-msg="searchInfo" @callFather="callFather"></pagination>
    <!-- 处理弹框 -->
    <el-drawer title="处理" :visible.sync="dealDrawer" :before-close="handleClose">
      <deal v-if="dealDrawer" @close='handleClose'  ref='newForm' :id="id" :row="row"></deal>
    </el-drawer>
    <!-- 摔倒弹框 -->
    <el-dialog title="详情" :visible.sync="open" width="50%" append-to-body>
      <el-form label-width="130px" style="display:flex;flex-wrap: wrap;">
        <el-form-item label="车辆信息：">
          <p class="detailInfo">
            {{ detailInfo.plateNumber ? detailInfo.plateNumber : '--' }}
            ({{ detailInfo.brand ? detailInfo.brand : '--' }})
          </p>
        </el-form-item>
        <el-form-item label="摔倒状态：" v-if="detailInfo.abnormalType == 5">
          <p class="detailInfo">{{detailInfo.status == 1 ? "已扶正" : "待扶正"}}</p>
        </el-form-item>
        <el-form-item label="车速：" v-if="detailInfo.abnormalType == 5">
          <p class="detailInfo">{{ detailInfo.currentSpeed }}km/h</p>
        </el-form-item>
        <el-form-item label="摔倒时间：" v-if="detailInfo.abnormalType == 5">
          <p class="detailInfo">{{ detailInfo.createTime }}</p>
        </el-form-item>
      </el-form>
      <div id="map" style="width:100%;height: 400px;"></div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="open = false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 超速弹框 -->
    <el-dialog :title="(detailInfo.plateNumber ? detailInfo.plateNumber : '--') + ' - ' + (detailInfo.brand ? detailInfo.brand : '--')" :visible.sync="speedOpen" width="50%" append-to-body>
      <div id="speedMap" style="width:100%;height: 400px;"></div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="speedOpen=false">关闭</el-button>
      </div>
    </el-dialog>
    <!-- 车辆情况 -->
    <el-dialog :title="`${carSituationInfo.plateNumber} - ${carSituationInfo.brand}`" :visible.sync="carSituationOpen" width="50%" append-to-body>
      <el-form label-width="130px" style="display:flex;flex-wrap: wrap;">
        <el-form-item label="车辆类型：">
          <p class="carSituationInfo">{{ carSituationInfo.standardType == 2 ? '绿标车' : (carSituationInfo.standardType == 3 ? '黄标车' : '临时车') }}</p>
        </el-form-item>
        <el-form-item label="是否在线：">
          <p class="carSituationInfo">{{carSituationInfo.status == 1 ? '在线' : '离线'}}</p>
        </el-form-item>
        <el-form-item label="电瓶是否离车：" v-if="carSituationInfo.deviceType == 1">
          <p class="carSituationInfo">{{carSituationInfo.isAccumulatorLeave == 0 ? '正常' : '离车'}}</p>
        </el-form-item>
        <el-form-item label="温度">
          <p class="carSituationInfo" v-if="carSituationInfo.bikeDevice">{{ `${carSituationInfo.bikeDevice.firstTemperature}℃，${carSituationInfo.bikeDevice.secondTemperature}℃` }}</p>
        </el-form-item>
        <el-form-item label="硬件版本号：">
          <p class="carSituationInfo" v-if="carSituationInfo.bikeDevice">{{carSituationInfo.bikeDevice.hardWareVersion ? carSituationInfo.bikeDevice.hardWareVersion : '--'}}</p>
        </el-form-item>
        <el-form-item label="软件版本号：">
            <p class="carSituationInfo" v-if="carSituationInfo.bikeDevice">{{ carSituationInfo.bikeDevice.version ? carSituationInfo.bikeDevice.version : '--' }}</p>
        </el-form-item>
        <el-form-item label="摔倒状态："  v-if="carSituationInfo.deviceType == 1">
          <p class="carSituationInfo" v-if="carSituationInfo.bikeDevice">{{ carSituationInfo.bikeDevice.isFall === 0 ? '正常' : (carSituationInfo.bikeDevice.isFall == 1 ? '摔倒' : '--') }}</p>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="carSituationOpen=false">关闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const XLSX = require("xlsx-js-style");
import deal from './deal.vue';
import pagination from '@/components/Pagination.vue';
import { getAbnormalList,checkSpeedDetail,getEBikeById,getNeighbourhoodList,exportAbnormalList,getMerchantList } from '@/api/public.js';
import { timeChange } from '@/api/publicFun.js';
import { abnormalTypeOptions,equipTypeOptions,getAbnormalTypeLabel } from "@/constants/abnormal.js";
export default {
  data() {
    return {
        time:"",
        searchInfo:{ // 条件筛选
            pageNo:1,
            pageSize:10,
            total:0,
            param:{
              abnormalType: null,
              deviceType: null,
              deviceId:null,
              status:null,
              name:null,
              phone:null,
              startDatetime:null,
              endDatetime:null,
              unitId:null,
              deviceBikeType:null,
            }
        },
        tableData:[], // 表格数据
        equipTypeOptions:equipTypeOptions, // 设备类型/异常类型联级选项
        managerUnitOptions:[],// 商户、小区选项
        unitId:"",
        props: { // 所属商户、小区 联动选择框配置
          value:'id',
          label:'name',
          lazy: true,
          checkStrictly:true,
          lazyLoad (node, resolve) {
            const { level } = node;
            setTimeout(() => {
              let data = {
                pageNo:1,
                pageSize:1000,
                param:{
                  name:null,
                  merchantId:node.value,
                }
              }
              if(level == 1){
                getNeighbourhoodList(data).then(res=>{
                // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  let nodes = [];
                  res.data.list.forEach(element=>{
                      nodes.push({
                        id: element.id,
                        name: element.name,
                        leaf: level >= 1,
                        children:null,
                      })
                  })
                  // 通过调用resolve将子节点数据返回，通知组件数据加载完成
                  resolve(nodes);
                })
                console.log(node,908)
              }
            }, 1000);
          }
        },
        statusOptions:[ // 是否处理选项
          {value:0,label:'未处理'},
          {value:1,label:'已处理'},
        ],
        unitOptions:[], // 小区选项
        abnormalType:[],
        equipType:'',
        dealDrawer:false, // 控制处理异常弹框是否显示
        Map:null, // 地图
        open:false, // 是否显示摔倒弹框
        speedOpen:false, // 是否显示超速弹框
        carSituationOpen:false, // 是否显示车辆情况弹框
        detailInfo:{}, // 异常详情信息
        carSituationInfo:{}, // 车辆情况
        userInfo:JSON.parse(localStorage.getItem('userInfo')),
        abnormalTypeOptions:abnormalTypeOptions, // 异常类型
    }
  },

  components: {
    deal,
    pagination,
  },

  computed: {},

  mounted() {
    this.init();
    this.userInfo.roleLevel < 5 ? this.getMerchantOptions() : this.getNieghbourList();
  },

  methods: {
    // 筛选表格待处理项标红
    tableRowClassName({row}) {
      if (row.status !== 1) {
        return 'warning-row';
      }
      return '';
    },
    // 条件筛选事件
    handleSearch(type){
      this.searchInfo.pageNo = 1;
      if(type == 'clear'){
        this.searchInfo.param = {
          abnormalType: null,
          deviceType: null,
          deviceId:null,
          status:null,
          name:null,
          phone:null,
          startDatetime:null,
          endDatetime:null,
          unitId:null,
          deviceBikeType:null,
        };
        this.abnormalType = [];
        this.time = '';
        this.unitId = "";
      }else{
        this.searchInfo.param.deviceId = this.searchInfo.param.deviceId == "" ? null : this.searchInfo.param.deviceId;
        this.searchInfo.param.status = this.searchInfo.param.deviceType == 1 && this.searchInfo.param.abnormalType == 4 ? null : this.searchInfo.param.status;
      }
      this.init();
    },
    // 获取商户列表
    async getMerchantOptions(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:'',
        }
      }
      await getMerchantList(data).then(res=>{
        if(res.success){
          this.managerUnitOptions = res.data.list;
          this.managerUnitOptions.forEach(element=>{
            element.children = [];
          })
        }else{
          this.managerUnitOptions = [];
        }
      })
    },
    // 选择商户、小区
    chooseMerchant(e){
      this.searchInfo.param.merchantId = e[0] ? e[0] : null;
      this.searchInfo.param.unitId = e[1] ? e[1] : null;
      console.log(e,998)
    },
    // 导出
    educe(){
      exportAbnormalList({param:this.searchInfo.param}).then(res=>{
        let dataList = res.data;
        const data = [
          ['车牌号','车主名称','车主电话','异常类型','是否处理','处理结果','异常时间','处理时间']
        ];
        dataList.forEach(element=>{
          let each = [];
          each.push(element.plateNumber ? element.plateNumber : '--');
          each.push(element.ownerName ? element.ownerName : '--');
          each.push(element.ownerPhone ? element.ownerPhone : '--');
          each.push(element.abnormalTypeStr ? element.abnormalTypeStr : '--');
          each.push(element.statusStr ? element.statusStr : '--');
          each.push(element.processingResult ? element.processingResult : '--');
          let eachTime = timeChange(element.createTime,'seconds');
          let updateTime = timeChange(element.updateTime,'seconds');
          each.push(eachTime);
          each.push(updateTime);
          data.push(each);
        })
        // excel导出
        const sheet = XLSX.utils.aoa_to_sheet(data);
        const book = XLSX.utils.book_new();
        // 设置列宽为自适应
        sheet['!cols'] = [{ wch: 15 },{ wch: 15 },{ wch: 18 },{ wch: 24 },{ wch: 15 },{ wch: 15 },{ wch: 20 },{ wch: 20 }];
        sheet['!rows'] = []; // 行高
        // 设置单元格样式
        for (let row = 0; row < data.length; row++) {
          for (let col = 0; col < data[row].length; col++) {
            sheet['!rows'].push({ hpx: 28 }) // 行高
            const cellRef = XLSX.utils.encode_cell({ c: col, r: row });
            console.log('单元格',cellRef)
            sheet[cellRef].s = {
              alignment: {
                horizontal: 'center', // 水平居中
                vertical: 'center',   // 垂直居中
              },
              font: {
                bold: true // 加粗
              }
            };
          }
        }
        XLSX.utils.book_append_sheet(book,sheet,"sheet1"); // 生成sheet
        XLSX.writeFile(book,`异常记录.xlsx`); // 导出
      })
    },
    // 获取小区列表
    getNieghbourList(){
      let data = {
        pageNo:1,
        pageSize:1000,
        param:{
          name:null,
        }
      }
      getNeighbourhoodList(data).then(res=>{
        if(res.success){
          this.unitOptions = res.data.list;
        }
      })
    },
    // 选择时间范围
    chooseTime(e){
      let start = timeChange(e[0]);
      this.searchInfo.param.startDatetime = `${start.year}-${start.month}-${start.day}`;
      let end = timeChange(e[1]);
      this.searchInfo.param.endDatetime = `${end.year}-${end.month}-${end.day}`;
    },
    // 获取表格数据
    async init(){
      await getAbnormalList(this.searchInfo).then(res=>{
        if(res.success){
          this.tableData = [...res.data.list];
          this.searchInfo.total = res.data.total;
          this.tableData.forEach(element => {
            element.createTime = timeChange(element.createTime,'seconds');
            element.updateTime = timeChange(element.updateTime,'seconds');
            element.abnormalTypeStr = getAbnormalTypeLabel(element.abnormalType);
          });
        }else{
          this.tableData = [];
          this.searchInfo.total = 0;
          this.$message({
            message:res.msg,
            type:'fail',
          })
        }
      })
    },
    // 联级选择器选择事件
    handleChoose(e){
      if(e[0] == 1){
        this.searchInfo.param.deviceBikeType = true;
        this.searchInfo.param.deviceType = e[1] ? e[1] : null;
        this.searchInfo.param.abnormalType = e[2] || e[2] == 0  ? e[2] : null;
      }else if(!e[0]){
        this.searchInfo.param.deviceBikeType = null;
        this.searchInfo.param.deviceType = null;
        this.searchInfo.param.abnormalType = null;
      }else{
        this.searchInfo.param.deviceBikeType = false;
        this.searchInfo.param.deviceType = e[0] ? e[0] : null;
        this.searchInfo.param.abnormalType = e[1] || e[1] == 0  ? e[1] : null;
      }
      if(this.searchInfo.param.abnormalType == 4){
        this.searchInfo.param.status = null;
      }
    },
    // 处理
    handleSolve(row){
      this.row = row;
      this.dealDrawer = true;
    },
    // 关闭侧边弹框
    handleClose(){
      this.dealDrawer = false;
      this.row = null;
      this.init();
    },
    // 查看详情
    async handleGps(row){
      if(row.abnormalType == 4){
        this.handleOverSpeed(row);
      }else{
        if(row.latitude){
          this.Map = null;
          this.detailInfo = row;
          this.open = true;
          this.$nextTick(()=>{
              this.Map = new BMapGL.Map("map") // 创建地图实例
              this.Map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
              this.Map.clearOverlays();
              var eachPoint = new BMapGL.Point(row.longitude, row.latitude);
              var marker = new BMapGL.Marker(eachPoint);
              this.Map.addOverlay(marker);
              this.Map.centerAndZoom(eachPoint,16);
          })
        }else{
          this.$message({
            message:'暂未获取到GPS',
            type:'error'
          })
        }
      }
    },
    // 查看超速详情
    async handleOverSpeed(row){
      this.detailInfo = row;
      await checkSpeedDetail({param:row.id}).then(res=>{
        if(res.success){
          this.Map = null;
          let all = res.data;
          this.speedOpen=true;
          this.$nextTick(()=>{
              this.Map = new BMapGL.Map("speedMap") // 创建地图实例
              this.Map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放
              this.Map.clearOverlays();
              if(all){
                let allGps = [];
                let speeds = [];
                all.forEach((element,index)=>{
                    allGps.push(new BMapGL.Point(element.longitude,element.latitude));
                    var eachPoint = new BMapGL.Point(element.longitude,element.latitude);
                    var marker = new BMapGL.Marker(eachPoint);
                    speeds.push({lng:element.longitude,lat:element.latitude})
                    this.Map.addOverlay(marker);
                    let eachTime = timeChange(element.createTime);
                    element.createTime = `${eachTime.year}-${eachTime.month}-${eachTime.day} ${eachTime.hour}:${eachTime.minutes}:${eachTime.seconds}`;
                    if(index === 0 ){
                      var content =`<div style='width:100%;'><div style='width:90%;margin:0 auto;'>` + `<div><span>时间：</span>` +element.createTime;
                      this.brokenInfo(marker,content);
                    }else{
                      var content =`<div style='width:100%;'><div style='width:90%;margin:0 auto;'>` + `<div><span>时间：</span>` +element.createTime + `</div><div><span>车速：</span>` + element.speed+'km/h';
                      this.brokenInfo(marker,content);
                    }
                })
                // 计算所有标点在一个比较舒适的初始缩放范围
                let view2 = this.Map.getViewport(eval(speeds));
                this.Map.centerAndZoom(view2.center, view2.zoom);
                var polyline = new BMapGL.Polyline(allGps, {strokeColor:"blue", strokeWeight:7, strokeOpacity:0.7,geodesic:true}); // 连点成线
                this.Map.addOverlay(polyline);
              }else{ // 没有gps
                this.overSpeedMap.centerAndZoom(new BMapGL.Point(120.207461,30.260319), 15.5);
                this.$message({
                  message:'未获取到GPS',
                  type:'error'
                })
              }
          })
        }
      })
    },
    // 查看车辆情况
    async carSituation(row){
      this.carSituationOpen = true;
      await getEBikeById({param:row.ebikeId}).then(res=>{
        if(res.success){
          this.carSituationInfo = res.data;
        }else{
          this.$message({
            message:res.msg,
            type:'fail'
          })
        }
      })
    },
    // 点击未处理异常标记点显示标点详细信息
    brokenInfo(marker,content) {
     var infoWindow = new BMapGL.InfoWindow(content, this.opts);
        marker.onclick=function(){
            marker.openInfoWindow(infoWindow);
        }
    },
    // 分页插件事件
    callFather(parm) {
      this.searchInfo.pageNo = parm.currentPage;
      this.init();
    },
  },
};
</script>
<style scoped lang="scss">
.reasonContainer{
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  .el-icon-info{
    cursor: pointer;
    margin-left: 5px;
  }
}
/deep/ .el-table .warning-row {
  // background: rgba(#dd8b8b, 0.3) !important;
  color: red !important;
}
.carSituationInfo{
  color: blue;
}
.each{
  width: 300px;
}
</style>
