<template>
    <section>
      <el-form ref="form" label-position="right" status-icon :model="form" :rules="rules" label-width="150px" style="width:95%;overflow:auto">
        <el-form-item label="处理结果" prop="processingResult" >
            <el-input type="textarea" v-model="form.processingResult"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button @click="close('form')">取消</el-button>
            <el-button type="primary" @click="submit('form')" :plain='true'>提交</el-button>
        </el-form-item>
      </el-form>
    </section>
  </template>
    
  <script>
    import { handleAbnormal } from '@/api/public.js';
    export default {
      props:['row'],
      data() {
        return {
          form:{ // 表单数据
              abnormalId:null, // 异常ID
              processingResult:'', // 处理结果
          },
          rules: { // 表单验证
            processingResult: [
              { required: true, message: '处理结果不得为空', trigger: 'blur', }
            ],
          },
        };
      },
    
      components: {},
    
      computed: {},
    
      mounted() {
        this.form.abnormalId=this.row.id;
      },
    
      methods: {
        // 关闭弹框
        close(){
           this.$emit('close');
        },
        // 提交
        submit(form) {
          this.$refs["form"].validate(valid => {
            if (valid) {
              this.$confirm('确认提交吗？').then(()=>{
                handleAbnormal({param:this.form}).then((res) => {
                  if (res.success) {
                    this.$message({
                      message: "提交成功",
                      type: "success",
                    });
                    setTimeout(() => {
                      this.$emit("close");
                    }, 1000);
                  }else{
                    this.$message({
                      message:res.msg,
                      type:'fail',
                    })
                  }
                })
              })
            } else {
                return false;
            }
          });
        },
      }
    };
  </script>
  <style lang="scss" scoped>
     .el-drawer{
       overflow:auto !important;
     }
     .avatar-uploader .el-upload {
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
      .avatar-uploader .el-upload:hover {
        border-color: #409EFF;
      }
      .avatar-uploader-icon {
        border: 1px dashed #ccc;
        border-radius: 50%;
        overflow:hidden;
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
      }
      .avatar {
        width: 178px;
        height: 178px;
        display: block;
      }
  </style>
    